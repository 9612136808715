@import "../../styles/variables.scss";

.item {
  flex-basis: 100%;
  box-shadow: 0px 0px 20px #52535326;
  border: 1px solid #e5dcd3;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  color: #002238;
  padding: 10px;

  @include not-mobile {
    flex-basis: calc(33% - 120px);
  }
}

.itemImageContainer {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemImage {
  height: 250px;
  width: 210px;
}

.itemTitle {
  font-weight: 500;
  font-size: 28px;
  text-align: center;
}

.itemDescription {
  font-size: 14px;
  text-align: center;
}

.itemPriceLabel {
  font-size: 11px;
  text-align: center;
  margin-top: 15px;
}

.itemDiscountPrice {
  text-decoration: line-through;
  color: #8b9195;
}

.itemPrice {
  font-size: 18px;
  font-weight: 700;
}
