.filterHeader {
  font-size: 14px;
  font-weight: 700;
  color: #002238;
  padding-bottom: 10px;
}

.filterButtonContainer {
  border-color: #000;
  border-radius: 24px;
  border-width: 1px;
  color: #002238;
  cursor: pointer;
  max-width: 328px;
  padding: 8px 24px;
}

.filterButtonText {
  text-align: center;
  display: block;
  font-size: 15px;
  font-weight: 700;
}

.filtereChevronSvg {
  float: right;
  transform: translateY(-165%);
}

.filterItemsContainer {
  width: 100%;
  height: 370px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
}

.filterItemsContainerHeading {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
}

.filterItemsContainerCloseButton {
  float: right;
  margin-right: 15px;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.filterItemsContainerScrollContainer {
  display: flex;
  align-items: center;
  padding-top: 30px;
  gap: 20px;
  overflow: scroll;
  scrollbar-width: none;
  margin-left: 25px;
  justify-content: center;
}

.machineContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  flex: 0 0 150px;

  &:hover {
    border-radius: 8px;
    border-width: 2px;
    border-color: #75a7ad;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
}

.selectedCompatibleMachineContainer {
  border-radius: 8px;
  border-width: 2px;
  border-color: #75a7ad;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.machineImg {
  width: 200px !important;
  height: 240px !important;
  object-fit: contain !important;
}

.machineTitle {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
}
