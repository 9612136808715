@import "../../styles/variables.scss";

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 70vh;
  align-items: center;
  justify-content: center;

  @include not-mobile {
    flex-direction: row;
    gap: 50px;
    align-items: flex-start;
    justify-content: center;
  }
}

.machineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.listingContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 58px 54px;
  margin-bottom: 60px;

  @media (max-width: $tablet-max) {
    padding: 0 20px;
    gap: 50px;
  }

  @media (max-width: $mobile-max) {
    gap: 30px;
  }
}

.heroContainer {
  width: 100%;
  height: 575px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $mobile-max) {
    height: 160px;
  }
}

.heroImage {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  position: absolute;
}

.heroText {
  font-family: Poppins;
  font-size: 70px;
  font-weight: 700;
  line-height: 76px;

  @media (max-width: $mobile-max) {
    font-size: 24px;
    line-height: 24px;
  }
}

.description {
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: #002238;
  margin-top: 60px;
  margin-bottom: 90px;

  @media (max-width: $mobile-max) {
    font-size: 14px;
    line-height: 18px;

    margin-top: 26px;
    margin-bottom: 37px;
  }
}
