@import "../../styles/variables.scss";

.item {
  border-radius: 20px;
  height: 343px;
  width: calc(50% - 28px);
  // max-width: 561px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eceeef;
  box-shadow: 0px 3px 18px 0px #5284841f;

  @media (max-width: $tablet-max) {
    width: 100%;
  }
}

.itemImageContainer {
  height: 100%;
  flex: 1;
  max-width: 220px;
  min-width: 130px;
  background-color: #75a7ad;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  align-self: stretch;

  @media (max-width: $mobile-max) {
    min-width: 100px;
  }

  a {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
  }
}

.itemImage {
  min-width: 100%;
  @media (min-width: $tablet-min) {
    bottom: 25px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 60%;
  height: 100%;
  flex: 2;

  @media (max-width: $tablet-max) {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 20px;
    padding-bottom: 18px;
  }
}

.infoDivider {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #002238;

  @media (max-width: $tablet-max) {
    font-size: 14px;
    line-height: 16px;
  }
}

.rating {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 16px;
}

.itemDescription {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #002238;
}

.colorOptionsContainer {
  display: flex;
  margin-top: 12px;
  gap: 12px;
  flex-wrap: wrap;
}

.colorOption {
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}

.colorLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedColor {
  position: absolute;
  border-width: 0.65px;
  border-style: solid;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.compareTableLink {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #005d6e;
  text-decoration: underline;
  width: max-content;
  margin: 16px 0;

  @media (max-width: $tablet-max) {
    font-size: 14px;
    line-height: 16px;
  }
}

.viewOffersButton {
  background-color: #d57461;
  color: white;
  border: none;
  padding: 11px 34px;
  border-radius: 22px;
  width: 78%;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-align: center;

  &:hover {
    background-color: #d57461;
  }

  @media (max-width: $tablet-max) {
    width: 100%;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 1.271px;
  }
}
